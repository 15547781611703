var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "motivo content-cancelamento" },
    [
      _c(
        "vs-row",
        [
          _vm.$route.name == "endosso-cancelamento"
            ? _c("vs-col", { attrs: { "vs-lg": "4", "vs-sm": "12" } }, [
                _vm.selectedReason
                  ? _c("h6", { staticClass: "text-grey-700 font-semibold" }, [
                      _vm._v(
                        "\n        A Apólice só poderá ser cancelada até o\n        " +
                          _vm._s(_vm.daysToCancel) +
                          "º dia da emissão da Apólice. Após esta data, não\n        poderá ser efetuado de forma automática.\n        "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n        O cancelamento automático só poderá ser realizado se não houver prêmio\n        quitado.\n      "
                      )
                    ])
                  : _vm._e()
              ])
            : _c("vs-col", { attrs: { "vs-lg": "4", "vs-sm": "12" } }, [
                _c("h6", { staticClass: "text-grey-700 font-semibold" }, [
                  _vm._v(
                    "\n        Selecione o motivo da alteração da apólice.\n      "
                  )
                ])
              ]),
          _c(
            "vs-col",
            { attrs: { "vs-lg": "8", "vs-sm": "12" } },
            [
              _c(
                "vs-row",
                [
                  _c(
                    "vs-col",
                    { staticClass: "mb-5", attrs: { "vs-w": "12" } },
                    [
                      _c("ValidationProvider", {
                        attrs: { name: "Motivo", rules: "required" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var errors = ref.errors
                              return [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "Selecione um motivo"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.motivoSelected()
                                      }
                                    },
                                    model: {
                                      value: _vm.razao,
                                      callback: function($$v) {
                                        _vm.razao = $$v
                                      },
                                      expression: "razao"
                                    }
                                  },
                                  _vm._l(_vm.listMotivos, function(item) {
                                    return _c("el-option", {
                                      key:
                                        item.ReasonEndorsementRequestDescription,
                                      attrs: {
                                        label:
                                          item.ReasonEndorsementRequestDescription,
                                        value:
                                          item.ReasonEndorsementRequestDescription
                                      }
                                    })
                                  }),
                                  1
                                ),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: errors[0],
                                        expression: "errors[0]"
                                      }
                                    ],
                                    staticClass: "text-danger text-sm"
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(errors[0]) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm.endossoObjeto.length > 0
                    ? _c(
                        "div",
                        [
                          _vm.listObject &&
                          _vm.listObject.length &&
                          _vm.endosso.EndorsementReasonId
                            ? _c("contractual-terms", {
                                attrs: {
                                  defaultTags: _vm.defaultTags,
                                  isEndosso: true,
                                  endossoObjeto: _vm.endossoObjeto,
                                  currentTab: "2"
                                },
                                on: {
                                  updatePropostaTermos: _vm.updatePropostaTermos
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  ((_vm.endosso || {}).Documents || []).length > 0
                    ? _c("div", { staticClass: " docs" }, [
                        _c("div", { staticClass: "head" }, [
                          _c("h3", [_vm._v("Documentos")])
                        ]),
                        ((_vm.endosso || {}).Documents || []).length > 0
                          ? _c("p", { staticClass: "pt-5 w-full" }, [
                              _vm._v("\n            Documentos marcados com "),
                              _c("b", { staticStyle: { color: "red" } }, [
                                _vm._v("*")
                              ]),
                              _vm._v(
                                " são\n            obrigatórios\n          "
                              )
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "flex pt-5",
                            staticStyle: {
                              "flex-wrap": "wrap",
                              "justify-content": "flex-start"
                            }
                          },
                          _vm._l(_vm.endosso.Documents, function(doc, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "py-2  col-documents"
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "font-bold text-sm name" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(doc.Name) +
                                        "\n                "
                                    ),
                                    _c("b", { staticStyle: { color: "red" } }, [
                                      _vm._v(_vm._s(doc.IsRequired ? "*" : ""))
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "endosso-documents" },
                                  [
                                    _c("p", { staticClass: "description" }, [
                                      _vm._v(_vm._s(doc.Description))
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "inline-flex pt-5" },
                                      [
                                        _c(
                                          "vs-chip",
                                          { class: _vm.getClassStatus(doc) },
                                          [
                                            _c("i", {
                                              class: [
                                                {
                                                  "onpoint-check-circle pr-2":
                                                    doc.files
                                                },
                                                {
                                                  "onpoint-warning pr-2": !doc.files
                                                }
                                              ]
                                            }),
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  doc.files
                                                    ? "Enviado"
                                                    : doc.IsRequired
                                                    ? "Pendente"
                                                    : "Opcional"
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        ),
                                        !doc.files
                                          ? _c(
                                              "vs-button",
                                              {
                                                staticClass: "py-2 px-3 ml-5",
                                                attrs: {
                                                  color: "primary",
                                                  type: "filled"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openModalUpload(
                                                      doc
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "onpoint-cloud-arrow-up icon-font-center"
                                                })
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.modalUpload
        ? _c("base64Upload", {
            attrs: {
              modalUpload: _vm.modalUpload,
              documentoName: (_vm.selectedDocumento || {}).Name
            },
            on: {
              callBackUpload: _vm.callBackUpload,
              closeModal: _vm.closeModal
            }
          })
        : _vm._e(),
      _c(
        "vs-popup",
        {
          staticClass: "fix-scroll",
          attrs: {
            title: "Adicionar documento personalizado",
            active: _vm.addCustomDocumentModal,
            id: "confirmacao-modal"
          },
          on: {
            "update:active": function($event) {
              _vm.addCustomDocumentModal = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-col items-center pt-10" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { label: "Nome do documento", size: "large" },
                model: {
                  value: _vm.customName,
                  callback: function($$v) {
                    _vm.customName = $$v
                  },
                  expression: "customName"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-right px-6 pb-6" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mt-8 mr-3",
                  attrs: { color: "grey" },
                  on: {
                    click: function($event) {
                      _vm.addCustomDocumentModal = false
                    }
                  }
                },
                [_vm._v("Cancelar")]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "mt-8 mr-3",
                  attrs: {
                    color: "primary",
                    type: "filled",
                    disabled: _vm.customName.length <= 0
                  },
                  on: {
                    click: function($event) {
                      return _vm.addDocumentAction()
                    }
                  }
                },
                [_vm._v("Prosseguir")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }